/**
 * @generated SignedSource<<9c4d34f992ba7b3ca76c92ca2c6b5947>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductPricingDetailsFragment$data = {
  readonly id: string;
  readonly type: ProductType;
  readonly registrationPricing: {
    readonly basePrice: number;
    readonly pricing: {
      readonly frequency: PricingFrequency | null;
      readonly kind: PricingKind;
      readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
    } | null;
  } | null;
  readonly registrationPricingRange: {
    readonly minPricing: {
      readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
    } | null;
    readonly maxPricing: {
      readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
    } | null;
  } | null;
  readonly includedInBenefits: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly membershipPlan: {
          readonly id: string;
          readonly name: string;
        };
        readonly pricing: {
          readonly frequency: PricingFrequency | null;
          readonly kind: PricingKind;
          readonly amountCents: number;
          readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
        };
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "ProductPricingDetailsFragment";
};
export type ProductPricingDetailsFragment$key = {
  readonly " $data"?: ProductPricingDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductPricingDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frequency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "pricingUtils_usePricingDisplayValue"
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideDrafts"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideNonPublic"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductPricingDetailsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationPricing",
      "kind": "LinkedField",
      "name": "registrationPricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pricing",
          "kind": "LinkedField",
          "name": "pricing",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationPricingRange",
      "kind": "LinkedField",
      "name": "registrationPricingRange",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Pricing",
          "kind": "LinkedField",
          "name": "minPricing",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pricing",
          "kind": "LinkedField",
          "name": "maxPricing",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "hideDrafts",
          "variableName": "hideDrafts"
        },
        {
          "kind": "Variable",
          "name": "hideNonPublic",
          "variableName": "hideNonPublic"
        }
      ],
      "concreteType": "MembershipBenefitNodeConnection",
      "kind": "LinkedField",
      "name": "includedInBenefits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipBenefitNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MembershipBenefit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "membershipPlan",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Pricing",
                  "kind": "LinkedField",
                  "name": "pricing",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amountCents",
                      "storageKey": null
                    },
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "773627e225827c020b293048f49889d3";

export default node;
